export default {
  name: "xcjcd",
  emits: ["initForm"],
  data() {
    return {
      currentSig: "",
      zfryVisible1: false,
      zfryVisible2: false,
      sdcrVisible: false,
      obj: {
        mcList: [],
        zfryList: []
      },
      xcjc: {
        id: 0,
        dxlb: "",
        mc: "",
        tyshxydm: "",
        scjyxkzh: "",
        fzr: "",
        dwdz: "",
        dwfl: "",
        xb: "",
        sfzh: "",
        mz: "",
        yb: "",
        dh: "",
        cz: "",
        lxr: "",
        jyfw: "",
        xz: "",
        cun: "",
        // wsbh: "农（查）[2024] 001号",
        nfbh: null,
        code: 0,
        jcrq: "",
        org_id: null,
        jcsy: "",
        rwly: "",
        zfry1: "",
        zfry2: "",
        zfryzjh1: "",
        zfryzjh2: "",
        zfryqm1: "",
        zfryqm2: "",
        fxwt: 0,
        jcnr: "",
        sjrqm: "",
        zfdd: "",
        csrq: null,
        gzdw: "",
        jgdx_id: null
      }
    };
  },
  methods: {
    // 时间转字符串
    convertDate2Str(date) {
      let yy = date.getFullYear();
      let mm = date.getMonth() + 1;
      let dd = date.getDate();
      let gettime = yy + "-" + mm + "-" + dd;
      return gettime;
    },
    mcChange() {
      let mcArr = JSON.parse(JSON.stringify(this.obj.mcList));
      let mcObj = mcArr.find(item => {
        return item.mc === this.xcjc.mc;
      });
      debugger;
      this.xcjc.dxlb = mcObj.dxlb;
      this.xcjc.tyshxydm = mcObj.tyshxydm;
      this.xcjc.scjyxkzh = mcObj.scjyxkzh;
      this.xcjc.fzr = mcObj.fzr;
      this.xcjc.dwdz = mcObj.dwdz;
      this.xcjc.dwfl = mcObj.dwfl;
      this.xcjc.xb = mcObj.xb;
      this.xcjc.sfzh = mcObj.sfzh;
      this.xcjc.mz = mcObj.mz;
      this.xcjc.yb = mcObj.yb;
      this.xcjc.dh = mcObj.dh;
      this.xcjc.cz = mcObj.cz;
      this.xcjc.lxr = mcObj.lxr;
      this.xcjc.jyfw = mcObj.jyfw;
      this.xcjc.xz = mcObj.xz;
      this.xcjc.cun = mcObj.cun;
      this.xcjc.csrq = mcObj.csrq;
      this.xcjc.gzdw = mcObj.gzdw;
      this.xcjc.jgdx_id = mcObj.id;
    },
    zfryChange(val) {
      let zfryArr = JSON.parse(JSON.stringify(this.obj.zfryList));
      switch (val) {
        case "执法人员1":
          this.xcjc.zfryzjh1 = zfryArr.find(item => {
            return item.name === this.xcjc.zfry1;
          }).card_num;
          break;
        case "执法人员2":
          this.xcjc.zfryzjh2 = zfryArr.find(item => {
            return item.name === this.xcjc.zfry2;
          }).card_num;
          break;
        default:
          break;
      }
    },
    nfbhChange(e) {
      this.GetCurrentCode(Number(e));
    },
    jcrqChange(e) {
      this.xcjc.jcrq = this.convertDate2Str(e);
    },
    csrqChange(e) {
      this.xcjc.csrq = this.convertDate2Str(e);
    },
    dxlbChange() {
      this.GetJgdx();
    },
    initForm(formData) {
      if (formData.id) {
        this.xcjc = formData;
        this.GetJgdx();
        if (this.xcjc.nfbh) {
          this.xcjc.nfbh = String(this.xcjc.nfbh);
        }
        if (this.xcjc.zfryqm1) {
          this.zfryVisible1 = true;
        }
        if (this.xcjc.zfryqm2) {
          this.zfryVisible2 = true;
        }
        if (this.xcjc.sjrqm) {
          this.sdcrVisible = true;
        }
      } else {
        this.xcjc = {};
        this.zfryVisible1 = false;
        this.zfryVisible2 = false;
        this.sdcrVisible = false;
      }
    },
    renewSignature(val) {
      this.openSignature(val);
    },
    openSignature(val) {
      this.currentSig = val;
      this.$refs.dialogDzqm.visible = true;
    },
    downloadDzqm(name) {
      let imgUrl = "";
      switch (name) {
        case "执法人员1":
          imgUrl = this.xcjc.zfryqm1;
          break;
        case "执法人员2":
          imgUrl = this.xcjc.zfryqm2;
          break;
        case "受检人":
          imgUrl = this.xcjc.sjrqm;
          break;
        default:
          break;
      }
      this.downImage(imgUrl, name);
    },
    downImage(url, name) {
      let Img = new Image(),
        dataURL = "";
      let fileName = name + '.png';
      Img.src = url;
      // 处理跨域
      Img.setAttribute("crossOrigin", "Anonymous");
      // 监听图片加载好
      Img.onload = function () {
        let canvas = document.createElement("canvas"),
          width = Img.width,
          height = Img.height;
        canvas.width = width;
        canvas.height = height;
        canvas.getContext("2d").drawImage(Img, 0, 0, width, height);
        dataURL = canvas.toDataURL("image/png");
        let eleLink = document.createElement("a");
        eleLink.download = fileName;
        eleLink.style.display = "none";
        eleLink.href = dataURL;
        document.body.appendChild(eleLink);
        eleLink.click();
        document.body.removeChild(eleLink);
      };
    },
    clearDzqm(name) {
      switch (name) {
        case "执法人员1":
          this.xcjc.zfryqm1 = "";
          this.zfryVisible1 = false;
          break;
        case "执法人员2":
          this.xcjc.zfryqm2 = "";
          this.zfryVisible2 = false;
          break;
        case "受检人":
          this.xcjc.sjrqm = "";
          this.sdcrVisible = false;
          break;
        default:
          break;
      }
    },
    getSignImg(val) {
      let name = this.currentSig;
      this.clearDzqm(name);
      if (val.length > 0) {
        switch (name) {
          case "执法人员1":
            this.xcjc.zfryqm1 = val;
            this.zfryVisible1 = true;
            break;
          case "执法人员2":
            this.xcjc.zfryqm2 = val;
            this.zfryVisible2 = true;
            break;
          case "受检人":
            this.xcjc.sjrqm = val;
            this.sdcrVisible = true;
            break;
          default:
            break;
        }
      }
      this.$refs.dialogDzqm.visible = false;
    },
    async GetJgdx() {
      let res = await this.$axios.get("/api/Supervise/GetJgdx", {
        params: {
          page: 1,
          limit: 1000,
          dxlb: this.xcjc.dxlb,
          xz: '',
          cun: '',
          mc: ''
        }
      });
      if (res.data.code === 200) {
        this.obj.mcList = res.data.data;
      } else {
        this.$ElMessage.error(res.data.message);
      }
    },
    GetCurrentCode(nfbh) {
      this.$axios.get("/api/BusinessXcjc/GetCurrentCode", {
        params: {
          nfbh: nfbh
        }
      }).then(res => {
        if (res.data.code === 200) {
          this.xcjc.code = res.data.result;
        } else {
          this.$ElMessage.error(res.data.message);
        }
      });
    },
    // 获取执法人员列表
    GetUsersByOrgId() {
      this.$axios.get("/web/UserManager/GetUsersByOrgId").then(resOrg => {
        if (resOrg.data.code === 200) {
          this.obj.zfryList = resOrg.data.result;
        } else {
          this.$ElMessage.error(resOrg.data.message);
        }
      });
    }
  },
  mounted() {
    this.GetJgdx();
    this.GetUsersByOrgId();
  }
};